.slick-caraousel
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.884),rgba(0, 0, 0, 0.884)),url('../assets/Images/bg.jpg');
    padding: 4rem 6rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slick-caraousel .slickHeading
{
    text-align: center;
    color: #ffffff;
    padding-top: 1rem;
    font-family: "Noto Sans Devanagari, sans-serif";
}

.slick-container
{
    width: 90vw;
    margin: 2rem auto;
}
              
.card
{
    height: 44rem;
    border-radius: 10px;
    border: none;
    background-color: black;
}

.card-bottom > span
{
    font-size: 1.2rem;
    color: black;
}

.card-bottom > strong
{
    color: black;
    font-size: 1.5rem;
}

.slick-container .slick-dots li.slick-active button:before {
    color: #F97D09 !important;
    font-size: 2rem;
}

.slick-container .slick-dots li button:before{
    color: #ffffff;
    font-size: 2rem;
    opacity: 1;
}

.card-top
{
    width: 100%;
    height: 55%;
}

.card-top img
{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px 10px 0 0;
}

.card-bottom
{
    padding: 1rem;
    font-family: "Noto Sans Devanagari, sans-serif";
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
}

.card-bottom .postDesc
{
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: justify;
    margin-top: 1rem;
    color: rgb(80, 80, 80);
}

.card-bottom .readMore
{
    /* font-weight: bold; */
    font-size: 1.2rem;
    cursor: pointer;
    border: 1px solid #F97D09;
    padding: 0.3rem 0.8rem;
    outline: none;
    background-color: #F97D09;
    color: #ffffff;
    border-radius: 3px;
}

.card-bottom .readMore:hover
{
    background-color: #ffffff;
    color: #F97D09;
}

.card-bottom .timeDesc
{
    font-weight: bold;
    font-size: 1.1rem;
    color: #008000;
}

.descHeading
{
    margin: 1rem 0;
    color: #F97D09;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.descHeading > p
{
    font-size: 1.5em;
    margin-bottom: 0;
    align-items: center;
}

.slick-slide > div
{
    margin: 20px 10px;
}
.slick-list 
{
    margin: 20px 10px;
}

.slick-slider
{
    padding-bottom: 0.1rem;
}

/* -----------------------------------media------------------------------------- */


@media (max-width: 992px)
{
    .slick-caraousel
    {
        padding: 2rem 6rem;
        padding-bottom: 4rem;
    }
    .slick-caraousel .slickHeading
    {
        padding-top: 2rem;
    }
}

@media (max-width: 768px)
{
    .slick-container
    {
        padding: 0 2rem;
        margin: auto;
        height: 52rem;
    }
    .slick-caraousel
    {
        padding: 0;
    }
    .card-bottom .postDesc
    {
        font-size: 1.4rem !important;
        margin-bottom: 1rem;
    }
    .slick-container .slick-dots li button:before
    {
        display: none;
    }
    .slick-caraousel .slickHeading
    {
        padding-top: 4rem;
    }
}

@media (max-width: 576px)
{
    .slick-caraousel
    {
        padding: 0;
    }
    .slick-container
    {
        padding: 0 5rem;
        height: 54rem;
    }
    .card
    {
        height: 42rem;
    }
    .card-bottom .postDesc
    {
        font-size: 1.4rem !important;
        margin-bottom: 1rem;
    }
    .slick-caraousel .slickHeading
    {
        padding-top: 4rem;
    }
}

@media (max-width: 480px)
{

    .slick-caraousel
    {
        padding: 0;
    }
    .slick-container
    {
        padding: 0 3rem;
        height: 53rem;
    }
    .card
    {
        height: 42rem;
    }
    .card-bottom .postDesc
    {
        font-size: 1.4rem !important;
        margin-bottom: 1rem;
    }
    .slick-caraousel .slickHeading
    {
        padding-top: 4rem;
    }
}

@media (max-width: 380px)
{
    .slick-caraousel
    {
        padding: 0 1.9rem;
    }
    .slick-container
    {
        padding: 0 1.5rem;
        margin: 0;
        /* margin-left: 0.5rem; */
        /* margin-right: 0.5rem; */
        height: 46rem;
    }
    .card
    {
        height: 38rem;
    }
    .card-bottom .postDesc
    {
        font-size: 1.2rem !important;
        margin-bottom: 1rem;
    }
    .slick-dots li.slick-active button:before 
    {
        font-size: 1rem;
    }
    .slick-dots li button:before
    {
        font-size: 1rem;
        opacity: 1;
    }
}

@media  (max-width: 280px) 
{
    .slick-caraousel
    {
        padding: 0 1.3rem;
    }
    .slick-container
    {
        padding: 0;
        margin: 0;
        /* margin-left: 0.5rem; */
        /* margin-right: 0.5rem; */
        height: 42rem;
    }
    .card
    {
        height: 34rem;
    }
    .card-bottom .postDesc
    {
        font-size: 0.9rem !important;
        margin-bottom: 1rem;
    }
    .slick-dots li.slick-active button:before {
        color: #f9b909 !important;
        font-size: 1rem;
    }
    .slick-dots li button:before{
        color: #ffffff;
        font-size: 1rem;
        opacity: 1;
    }
}

