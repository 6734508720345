
.footer
{
    background: rgb(83, 83, 83);
    padding: 2rem;
}

.info-section
{
    display: flex;
    justify-content: space-around;
    padding: 1rem;
    width: 100%;
}

.address-section
{
    color: #ffffff;
    font-size: 1.7rem;
}

.address-section h1
{
    font-family: "Noto Sans Devanagari, sans-serif";
    font-size: 2.5rem;
}

.social-section
{
    display: flex;
    justify-content: center;
}

.instagram
{
    margin: 0 2rem;
}

.social-section .icon
{
    height: 4.5rem;
    width: 4.5rem;
    background-color: #F97D09;
}

.address-section table tbody td
{
    padding: 0 0.5rem;
}

.address-section table tbody td:first-child
{
    vertical-align: top;
}

/* ------------------------media-------------------------------------------- */

@media (max-width: 576px)
{
    .footer
    {
        padding-bottom: 5rem;
    }
}

@media (max-width: 480px) 
{
    .footer
    {
        padding-bottom: 4rem;
    }
    .footer .icon
    {
        width: 4rem;
        height: 4rem;
    }
}

@media (max-width: 380px)
{
    .address-section
    {
        font-size: 1.5rem;
    }
}

@media (max-width: 280px)
{
  .footer .icon a
  {
        width: 3rem;
        height: 3rem;
  }
  .footer .instagram
  {
    text-align: center;
  }
}