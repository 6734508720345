.banner
{
    width: 95%;
    margin: auto;
    /* border: 2px solid red; */
    height: auto;
}

.blog_page
{
    /* background-color: rgb(250, 250, 250); */
    padding: 4rem 0;
}

.banner img
{
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    border-radius: 20px;
}

.blog_container
{
    width: 90vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin: auto;
    padding: 4rem 0;
}

.blog_container .blog-card
{   
    width: 34rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 10px;
}

.blog-card .image
{
    height: 26rem;
    width: 100%;
}

.blog-card .image img
{
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
    transition: all 0.3s ease-in-out;
}

.blog-card .image img:hover
{
    transform: scale(1.5);
    object-fit: contain;
}

.blog-card .card-bottom
{
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
}

.blog-card .card-bottom .item_head > p
{
    font-weight: bold;
    font-family: "Noto Sans Devanagari, sans-serif";
    color: #F97D09;
    font-size: 2.2rem;
    /* color: gray; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: start;
}

.blog-card .card-bottom p
{
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: justify;
    font-weight: bold;
    margin: 1rem 0;
}

.blog-card a
{
    font-size: 1.3rem;
}

.blog-card .blog_date
{
    font-weight: bold;
    font-size: 1.3rem;
    color: gray;
    margin-bottom: 1rem !important;
    margin-top: 0 !important;
}

.blog-card .readMore
{
    border: 1px solid #F97D09;
    background-color: #F97D09;
    color: #ffffff;
    font-size: 1.2rem;
    outline: none;
    font-weight: bold;
    border-radius: 3px;
    padding: 0.5rem 1rem;
}

.blog-card .readMore:hover
{
   border: 1px solid #F97D09;
   background-color: #ffffff;
   color: #F97D09;
}

.blog_page .search-box
{
    width: 90vw;
    margin: auto;
    margin-top: 4rem;
    border-left: 8px solid #F97D09;
    padding-left: 2rem;
}

.blog_page .search-box input
{
    width: 30rem;
    height: 4rem;
    border: 1px solid gray;
    outline: none;
    border-radius: 20px;
    font-size: 1.6rem;
    padding: 0 1.5rem;
}

.blog_page .search-box input:focus
{
    border-color: #F97D09;
    box-shadow:  #F97D09 0px 2px 5px -1px, #F97D09 0px 1px 3px -1px;
}

.pagination-container .pageButton
{
    border: none;
    outline: none;
    height: 3rem;
    width: 3rem;
    font-weight: bold;
    border-radius: 50%;
}

.next-box, .prev-box
{
    cursor: pointer;
}

.pagination-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 1.2rem;
}

.pagination-container .activePage
{
    border: none;
    outline: none;
    height: 3rem;
    width: 3rem;
    background-color: #F97D09;
    border-radius: 50%;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2rem;
}

.blog_container h1
{
    color: gray;
}

/* -------------------------------media------------------------------------ */


@media (max-width: 576px) 
{
    .blog_container .blog-card
    {
        max-width: 34rem;
    }
}

@media (max-width: 480px) 
{
    .blog_page .search-box
    {
        text-align: center;
        border-left: 0px;
        padding-left: 0px;
    } 
    .banner
    {
        height: 11vh;
    }
    /* .banner img
    {
        width: 100%;
        height: 100%;
        object-fit: fill;
    } */
    .blog-card .card-bottom .item_head > p
    {
        font-size: 2rem;
    }
}

@media (max-width: 380px) 
{
    .blog_container .blog-card
    {   
        /* height: 45rem; */
        width: 100%;
    }  
    .blog-card .image
    {
        height: auto;
    }
    .blog-card p
    {
        font-size: 1.2rem;
    }
    .blog_page .search-box input
    {
        height: 3.5rem;
        width: 100%;
        border-radius: 15px;
        font-size: 1.4rem;
    }
    .banner
    {
        height: 11vh;
    }
}

@media (max-width: 300px) 
{
    .blog-card p
    {
        font-size: 0.7rem;
    }
    .banner
    {
        height: 8vh;
    }
}



