@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

/* color : #F97D09 */
/* color : #f9b909 */
/* font-family: "Noto Sans Devanagari, sans-serif"; */

*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}



html
{
  font-size: 62.5%;
}
