.contact-section 
{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 3rem 2rem;
    gap: 2rem;
}

.contact-section .box
{
    width: 33rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     */
      box-shadow:  -15px -15px 15px rgba(255, 255, 255, 0.2),
                    15px 15px 15px rgba(0,0,0,0.1),
                    inset -50px -50px 50px rgba(255, 255, 255, 0.2),
                    inset 50px 50px 50px rgba(0,0,0,0.1);
    border-radius: 20px;
    padding: 2rem;
    /* margin: 1rem; */
}

.contact-container > h1
{
    color: #F97D09;
    font-size: 3rem;
}

.contact-container > hr, .contact-container .line
{
    margin: 0;
    background-color: gray;
    height: 0.8rem;
    border: 0;
}

.contact-section .box1
{
    width: 33rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin: 1rem;
    border-radius: 20px;
    background-color: #ffffff;
}

.contact-section .box-top
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9b909;
    width: 100%;
    padding-top: 1.5rem;
    border-radius: 20px 20px 0 0;
}

.contact-container .box-bottom
{
    width: 100%;
    padding: 1rem;
    padding-top: 2rem;
    background-color: #ffffff;
    border-radius: 20px;
}

.box p, .box1 p
{
    font-size: 1.4em;
    text-align: center;
}

.box h1, .box h3, .box1 h1, .box1 h3
{
    font-family: "Noto Sans Devanagari, sans-serif";
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
}

.box a, .box1 a
{
    text-decoration: none;
}

.box .icon
{
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    background-color: #f9b909;
    margin-bottom: 1rem;
}

.box1 .img-icon
{
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    margin-bottom: 1rem;
}

.img-icon img
{
    width: 100%;
    height: 100%;
    display: block;
    /* object-fit: cover; */
    object-position: center;
    object-fit: fill;
}

.address
{
    font-weight: bold;
}

.box-bottom p
{
    margin: 0.5rem 0;
}

.box-bottom table
{
    width: 100%;
}

.box-bottom table tr:not(:last-child)
{
    border-bottom: 1px solid rgb(224, 224, 224);
}

.box-bottom table tbody td
{
    max-width: 16.5rem;
    text-align: center;
}

/* -----------------------------media--------------------------------------------- */


@media (max-width: 992px)
{
    .contact-section
    {
        flex-wrap: wrap;
    }
    .contact-section .box
    {
        width: 28rem;
    }
    .box p
    {
        font-size: 1.7rem;
    }
    .icon
    {
        /* height: 3rem;
        width: 3rem; */
        font-size: 1rem;
    }
}

@media (max-width: 768px)
{
    .contact-section
    {
        flex-wrap: wrap;
    }
    .box
    {
        margin-top: 2rem;
    }
    .box h1
    {
        font-size: 2.5rem;
    }
    .contact-container > h1
    {
        font-size: 2.9rem;
    }
}

@media (max-width: 576px)
{
    .contact-section
    {
        flex-wrap: wrap;
    }
    .box
    {
        margin-top: 2rem;
    }
    .box h1
    {
        font-size: 2.3rem;
    }
}

@media (max-width: 480px)
{
    .contact-section .box1, .contact-section .box-top
    {
        border-radius: 15px;
    }
    .contact-section .box
    {
        width: 28rem;
    }
    .box .icon
    {
        height: 4rem;
        width: 4rem;
        font-size: 0.8rem;
    }
    .box h1
    {
        font-size: 1.8rem;
    }
    .contact-container > h1
    {
        font-size: 2.6rem;
    }
    .box p, .box1 p
    {
        font-size: 1.3rem;
    }
}

@media (max-width: 380px)
{
    .box h1
    {
        font-size: 1.9rem;
    }
    .contact-container > h1
    {
        font-size: 2.5rem;
    }
    .box p, .box1 p
    {
        font-size: 1.1rem;
    }
}

@media (max-width: 280px)
{
    .box .icon
    {
        height: 3rem;
        width: 3rem;
        font-size: 0.6rem;
    }
    .box h1
    {
        font-size: 1.6rem;
    }
}