.about-page-container .image
{
    height: auto;
    width: 95vw;
    margin: 2rem auto auto auto;
}

.about-page-container .image img
{
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.box-container
{
    margin: 8rem auto;
    width: 90vw;
}

.box-container .box
{
    /* height: 20rem; */
    width: 100%;
    border: 3px solid;
    margin: auto;
    position: relative;
    padding: 4rem;
    border-image: linear-gradient(#f97d09, #ffff00) 30;
}

.box-container .box p, .box-container li
{
    font-size: 1.7rem;
    font-weight: 500;
    text-align: justify;
    line-height: 160%;
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Roboto', sans-serif; */
    vertical-align: baseline;
}

.box-container .box-1, .box-container .box-3
{
    border-right: 0px;
    border-bottom: 0px;
    /* border-top-color: linear-gradient(90deg, rgba(249,125,9,1) 0%, rgba(255,255,0,1) 100%); */
}

.box-container .box-5
{
    border-right: 0px;
}

.box-container .box-2, .box-container .box-4
{
    border-left: 0px;
    border-bottom: 0px;
}

.box-container .box h3
{
    font-family: "Noto Sans Devanagari, sans-serif";
    position: absolute;
    top: -1.5rem; 
    background-color: #ffffff;
    padding: 0 0.5rem;   
    margin: 0;
    color: #f97d09;
    font-size: 2.5rem;
}   

/* ----------------------------------media-------------------------------------- */

@media (max-width: 922px) 
{
    .about-page-container .image
    {   
        height: auto;
        width: 80vw;
    }
}

@media (max-width: 768px) {
    .box-container .box h3
    {
        font-size: 2.3rem;
    }
}

@media (max-width: 576px) 
{
    
    .box-container .box p, .box-container li
    {
        font-size: 1.3rem;
    }
    .box-container .box
    {
        padding: 3rem;
    }
    .box-container .box h3
    {
        margin-top: 0.2rem;
        font-size: 2.1rem;
    }
}

@media (max-width: 480px) 
{
    .about-page-container .image
    {   
        height: auto;
        width: 90vw;
    }
    .about-page-container .image img
    {
        border-radius: 10px;
    }
    .box-container
    {
        width: 90vw;
        margin: 5rem auto;
    }
    .box-container .box p, .box-container li
    {
        font-size: 1.2rem;
    }
    .box-container .box
    {
        padding: 2rem;
    }
    .box-container .box h3
    {
        margin-top: 0.2rem;
        font-size: 2rem;
    }
}

@media (max-width: 380px) 
{
    .box-container .box h3
    {
        margin-top: 0.4rem;
        font-size: 1.7rem;
    }
    .box-container .box p, .box-container li
    {
        font-size: 1.1rem;
    }
}

@media (max-width: 280px) 
{
    .box-container .box p, .box-container li
    {
        font-size: 1rem;
    }
    .box-container .box
    {
        padding: 2rem;
    }
    .box-container .box h3
    {
        font-size: 1.6rem;
    }
    .box-container .box p, .box-container li
    {
        font-size: 0.9rem;
    }
}